<template>
  <div class="member-shop-giveaway-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="活動名稱" prop="name" align="center" />
      <BaseElTableColumn label="狀態" align="center">
        <template slot-scope="scope">
          <Tag :type="scope.row.status.tagType">
            {{ scope.row.status.label }}
          </Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="開始時間" prop="startAt" align="center" />
      <BaseElTableColumn label="結束時間" prop="endAt" align="center" />
      <BaseElTableColumn fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="onEdit(scope.row)"
            @delete="onDelete(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteGiveaway"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import Tag from '@/components/Tag/Tag.vue'
import { DeleteMemberShopGiveaway } from '@/api/memberShop'
import { giveawayStatusConfig } from '@/config/memberShop'
import { get } from 'lodash'
import store from '@/store'
import { formatDate } from '@/utils/date'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'MemberShopGiveawayTable',
  components: { EmptyBlock, DeleteDialog, Tag },
  props: {
    tableData: { type: Array, default: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const shopId = computed(() => store.getters.shop)
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
    })
    const displayData = computed(() => {
      return props.tableData.map(i => {
        return {
          id: i.id,
          name: i.name,
          status: get(giveawayStatusConfig, i.status),
          startAt: formatDate(i.startAt),
          endAt: formatDate(i.endAt),
        }
      })
    })
    const deleteGiveaway = async () => {
      const [, err] = await DeleteMemberShopGiveaway({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('刪除成功！')
      modal.delete = false
      emit('refresh')
    }
    const onEdit = (row) => {
      router.push({
        name: 'MemberShopGiveawayEdit',
        params: {
          id: row.id,
        },
      })
    }
    const onDelete = async (row) => {
      selectRow.value = row
      modal.delete = true
    }
    return { displayData, onDelete, onEdit, modal, deleteGiveaway }
  },
})
</script>

<style scoped lang="postcss">

</style>
